import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Link, useNavigate, useParams, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Formik, Form, Field, useFormikContext} from 'formik';
import * as Yup from 'yup';
import SelectField from 'components/SelectField';
import VehiclePicker from "../components/Buy/VehiclePicker/VehiclePicker";
import FullscreenLoader from "../components/FullscreenLoader/FullscreenLoader";
import Icon from "../components/Icon";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {PARKING_TIME_VALUES, PARKING_TIME_VALUES_COPY} from "../constants";
import { fetchData } from "../services/api";
import { isSafari } from "../model/helpers/BrowserHelper";
import { orderTicket } from "../model/helpers/Payment";
import useCardsState from "../model/useCardsState";
import useVehiclesState from "../model/useVehiclesState";
import useTokenSessionState from "../model/useTokenSessionState";
import styles from './Buy.module.scss';
import parkingIcon from '../assets/images/parking.svg';
import timeIcon from '../assets/images/time.svg';
import pricingIcon from '../assets/images/pricing.svg';
import useEmailsState from "../model/useEmailsState";
import {CheckLicencePlate} from "../model/helpers/CheckLicencePlate";
import useBuyFormValues from "../model/useBuyFormValues";
import useSettingsState from "../model/useSettingsState";
import {usePreviousLocation} from "../hooks/PreviousLocationContext";
import {colourStyles} from "../components/form/Select/Select";
import SelectOption from "../components/SelectOption/SelectOption";
import useLocalStorageState from "../hooks/useLocalStorageState";
import i18n from "i18next";
import {randomString} from "../services/randomString";
import EmailPicker from "../components/Buy/EmailPicker/EmailPicker";
import parkingMachine from '../assets/images/parking-machine.svg';
import infoBox from '../assets/images/info-solid.svg';
import useParkingSession from "../model/useParkingSession";

dayjs.extend(isBetween);

type FormValues = {
    email: string;
    licencePlate: string;
    requestedMinutes: {
        value: string;
        label: string;
        labelString: string;
        isDisabled: boolean;
    }
};

const formatDateTo = (minutes) => {
    return dayjs().add(minutes, "minutes").format("DD. MM. HH:mm");
}


const Buy = () => {
    const formikRef = useRef(null);
    const { t } = useTranslation();
    const options = PARKING_TIME_VALUES;
    // K uchování options bez zohlednění aktuální parkovací relace
    const optionsWithoutSession = PARKING_TIME_VALUES_COPY;

    const lang = i18n.language;

    const [paymentMethod, setPaymentMethod] = useState("Card");
    const { cards } = useCardsState();
    const { add: addTokenSession } = useTokenSessionState();
    const [selectCard, setSelectCard] = useState(false);
    const [toDate, setToDate] = useState(formatDateTo(Number(options[0].value)));
    const [parkingZone, setParkingZone] = useState(null);
    const [priceForParking, setPriceForParking] = useState(null);
    const [requestedMinutes, setRequestedMinutes] = useState(options[0]);
    const { getAll: getCurrentParkingSessions } = useParkingSession();

    const [emailValue, setEmailValue] = useState(null);
    const [licencePlateValue, setLicencePlateValue] = useState(null);

    const [disablePaymentButtons, setDisablePaymentButtons] = useState(true);
    const [disablePaymentButtonsDueToLimitExceeded, setDisablePaymentButtonsDueToLimitExceeded] = useState(false);
    const { vehicles } = useVehiclesState();
    const { emails } = useEmailsState();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    let { sectionCode, featureType } = useParams();
    const [savedValuesID, setSavedValuesID] = useState(null);
    const { add: addBuyFormValues, getLatestByDateTime: getBuyFormValuesLatest, deleteRecord: deleteBuyFormValues } = useBuyFormValues();
    const { getSettingsValue, add: addSettingsValue, settings, deleteRecord: deleteSettingsValue} = useSettingsState();

    let paramsString = window.location.search;
    const searchParams = useMemo(() => new URLSearchParams(paramsString), [paramsString]);

    const [hasAutoLocation, setHasAutoLocation] = useLocalStorageState('map_autoLocation', true);

    const [hasApiError, setHasApiError] = useState(false);

    const [usePaymentMethod, setUsePaymentMethod] = useState(null);
    const [cardTokenValue, setCardTokenValue] = useState(null);

    useEffect(() => {
        if (featureType !== 'pm' && featureType !== 'pz' && featureType !== 'ib') {
            navigate(`/`);
        }
    }, [featureType]);

    useEffect(() => {
        if (hasApiError) {
            setDisablePaymentButtons(false);
            setShowLoader(false);
            setSelectCard(false);

            setTimeout(() => {
                setHasApiError(false);
            }, 5000);
        }
    }, [hasApiError]);

    const [lastUsedEmail, setLastUsedEmail] = useLocalStorageState('buy_lastUsedEmail', '');

    const handleOrderTicket = (payment, data, bypass = false) => {

        setUsePaymentMethod(payment);
        setCardTokenValue(data.cardToken);

        if (emailValue) {
            setLastUsedEmail(emailValue);
        } else {
            if (!bypass) {
                setShowNoEmailWarning(true);
                return;
            }
        }

        if (payment === '-9') {
            setSelectCard(true);
            return;
        }

        // Save values to IndexedDB when payment is canceled by user
        if (!savedValuesID) {
            addBuyFormValues({
                dateTime: new Date(),
                values: {
                    licencePlate: licencePlateValue,
                    email: emailValue,
                    requestedMinutes: requestedMinutes.value,
                },
                featureType: featureType,
                sectionCode: parkingZone?.sectionCode
            }).then((id) => {
                setSavedValuesID(id);
                setDisablePaymentButtons(true);
                setShowLoader(true);
                deleteSettingsValue('sentToPaymentGateway').then(r => {
                    addSettingsValue({
                        key: 'sentToPaymentGateway',
                        value: true
                    }).then(r => {
                        setHasAutoLocation(false);
                        orderTicket(featureType, lang, licencePlateValue, emailValue, requestedMinutes.value, payment, parkingZone, cardTokenValue ?? data.cardToken, addTokenSession, priceForParking ?? parkingZone?.tariffInfo?.minPrice, navigate);
                    });
                });
            });
        }

        if (cardTokenValue === "new") {
            setHasAutoLocation(false);
            orderTicket(featureType, lang, licencePlateValue, emailValue, requestedMinutes.value, 'Card', parkingZone, null).then((r) => {
                if (r?.success === false) {
                    setHasApiError(true);
                }
            });
            return;
        }
    }

    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            setIsSettingsLoaded(true);
        }
    }, [settings]);

    const previousLocation = usePreviousLocation();

    useEffect(() => {
        const checkGateway = async () => {
            if (isSettingsLoaded && !searchParams.has("getLatestValues") && (!previousLocation.startsWith("/pz") && !previousLocation.startsWith("/pm") && !previousLocation.startsWith("/ib"))) {
                const sentToPaymentGateway = await getSettingsValue('sentToPaymentGateway');

                if (sentToPaymentGateway?.value === true) {
                    deleteSettingsValue('sentToPaymentGateway').then(r => {
                        searchParams.append("getLatestValues", "true");
                        navigate({
                            pathname: location.pathname,
                            search: searchParams.toString(),
                        }, { replace: true });
                        setDisablePaymentButtons(false);
                        setShowLoader(false);
                        setSelectCard(false);
                    });
                }
            }
        };

        checkGateway();
    }, [isSettingsLoaded]);

    useEffect(() => {
        options.forEach((el, i) => {
            el.label = t(PARKING_TIME_VALUES[i].labelString);
        })
    }, [t, options]);

    const prepareOptions = (zone, optionsObj) => {
        const activeTariffs = [];
        zone.tariffInfo.activeTariffGroups.forEach((group) => {
            group.activeTariffDefinitions.forEach((period, index, definitions) => {
                if (period.pricePerHour > 0) {
                    const timeFrom = dayjs(period.timeFrom, 'HH:mm:ss');
                    let timeTo = dayjs(period.timeTo, 'HH:mm:ss');

                    // If timeTo is 00:00:00, treat it as 23:59:59
                    if (timeTo.format('HH:mm:ss') === '00:00:00') {
                        timeTo = dayjs('23:59:59', 'HH:mm:ss');
                    }

                    const nextPeriod = definitions[index + 1] || null;
                    let nextTimeFrom = null;
                    if (nextPeriod) {
                        nextTimeFrom = dayjs(nextPeriod.timeFrom, 'HH:mm:ss');
                    } else {
                        // Handle rollover to the next day
                        nextTimeFrom = dayjs(definitions[0].timeFrom, 'HH:mm:ss').add(1, 'day');
                    }

                    const minutesToNext = nextTimeFrom.diff(timeTo, 'minute');

                    // Pro dopočtení free doby mezi tarify si uložíme minuty do dalšího placeného intervalu
                    activeTariffs.push({
                        timeFrom: timeFrom,
                        timeTo: timeTo,
                        minutesToNext: minutesToNext
                    });
                }
            });
        });

        optionsObj.forEach((el) => {
            if (Number(el.value) > (zone.tariffInfo.maxHour * 60)) el.isDisabled = true;

            activeTariffs.forEach((tariff) => {
                const computeDateMinutes = Number(el.value);
                let computeDate = dayjs().add(computeDateMinutes , 'minutes');

                if (!computeDate.isBetween(tariff.timeFrom, tariff.timeTo, null, '[)')) {
                    computeDate = computeDate.add(tariff.minutesToNext, 'minutes');
                }

                if (!dayjs().isSame(computeDate, 'day') && !el.isDisabled) {
                    el.optionHeaderText = `${t('buy.toDate')} ${computeDate.format("DD. MM. HH:mm")}`;
                } else {
                    el.optionHeaderText = null;
                }
            })
        })
    };

    useEffect(() => {
        options.forEach((el) => {
           el.isDisabled = false;
        })

        if (featureType === "pz") {
            fetchData("StreetParking/sections/1", "GET", "url", {
                "sectionCode": sectionCode
            }).then(async (data) => {
                if (!data[0]) navigate(`/`);
                setParkingZone(data[0]);
                let zone = data[0];

                prepareOptions(zone, options);

                prepareOptions(zone, optionsWithoutSession);
            });
        }

        if (featureType === "pm" || featureType === 'ib') {
            fetchData("StreetParking/parkMachines/1", "GET", "url", {
                "parkMachineCode": sectionCode
            }).then(async (data) => {
                if (!data[0]) navigate(`/`);
                setParkingZone(data[0]);
                let zone = data[0];
                prepareOptions(zone, options);

                prepareOptions(zone, optionsWithoutSession);
            });
        }
    }, [sectionCode, navigate, options]);

    useEffect(() => {
        if (parkingZone?.tariffInfo && requestedMinutes.value) {
            fetchData("Tickets/streetParking/calculateprice", "POST", "body", {
                "licensePlate": licencePlateValue || randomString(10).toUpperCase(),
                "sectionCode": parkingZone?.sectionCode,
                "tariff": parkingZone?.tariffInfo?.tariffID,
                "requestedMinutes": Number(requestedMinutes.value)
            }).then((data) => {
                const minutesAccepted = Number(data.minutesAccepted + 1);
                setToDate(formatDateTo(minutesAccepted));

                setPriceForParking(Number(data.priceTotal));

                getCurrentParkingSessions().then((sessions) => {
                    let isLimitExceeded = false;

                    sessions.map((session) => {
                        if (session.ticket.licensePlate === licencePlateValue
                            &&
                            (
                                (session.ticket.sectionCode !== null && session.ticket.sectionCode === sectionCode)
                                ||
                                (session.ticket.parkMachineCode !== null && session.ticket.parkMachineCode === parkingZone?.parkMachineCode)
                            )
                        ) {
                            let prevMinutes = null;

                            options.forEach((el) => {
                                // V případě, že mám existující session a vybraný vyšší čas, je potřeba vybrat nejvyšší nižší čas
                                if (Number(el.value) > (session.maxHour * 60 - session.acceptedMinutes)) {
                                    el.isDisabled = true;
                                    if (Number(requestedMinutes?.value) > Number(prevMinutes?.value)) {
                                        if (formikRef.current) {
                                            formikRef.current.setFieldValue("requestedMinutes", prevMinutes);
                                        }

                                        setRequestedMinutes(prevMinutes);
                                    }
                                    isLimitExceeded = true;
                                }

                                prevMinutes = el;
                            })

                        } else if(licencePlateValue &&
                            (
                                (session.ticket.sectionCode !== null && session.ticket.sectionCode === sectionCode)
                                ||
                                (session.ticket.parkMachineCode !== null && session.ticket.parkMachineCode === parkingZone?.parkMachineCode)
                            )
                        ) {
                            options.forEach((el, optionIdx) => {
                                el.isDisabled = optionsWithoutSession[optionIdx].isDisabled === true;
                            })

                            isLimitExceeded = false;
                        }
                    })

                    setDisablePaymentButtonsDueToLimitExceeded(isLimitExceeded);
                });

            });
        }
    }, [parkingZone, requestedMinutes, licencePlateValue]);

    const [hasAutoEmail, setHasAutoEmail] = useState(false);
    const [hasLicencePlateWarning, setHasLicencePlateWarning] = useState(false);

    const [latestValues, setLatestValues] = useState(false);

    const [checkedLatestValues, setCheckedLatestValues] = useState(false);

    const FormikSetValues = () => {
        const {values, errors, touched, setValues, setTouched} = useFormikContext<FormValues>();

        // Load values from last saved form (when payment is cancelled)

        useEffect(() => {
            if (!latestValues && values.licencePlate === '') {
                if (searchParams.get("getLatestValues") === "true") {
                    searchParams.delete("getLatestValues");
                    navigate({
                        pathname: location.pathname,
                        search: searchParams.toString(),
                    }, { replace: true });

                    getBuyFormValuesLatest().then((latest) => {
                        if (latest) {
                            setValues((prevValues) => ({
                                ...prevValues,
                                email: latest.values.email,
                                licencePlate: latest.values.licencePlate,
                                requestedMinutes: options.find(option => option.value ===  latest.values.requestedMinutes) || null
                            }));

                            setRequestedMinutes(latest.values.requestedMinutes);
                            setLatestValues(true);
                            setHasAutoEmail(true);
                            setTouched({licencePlate: true, email: true, requestedMinutes: {
                                    value: true,
                                    label: true,
                                    labelString: true,
                                    isDisabled: true,
                                }});
                            //deleteBuyFormValues(latest.id);
                        }
                    });
                }
                setCheckedLatestValues(true);
            }
        }, [values.licencePlate]);

        useEffect(() => {
            if (checkedLatestValues) {
                if (CheckLicencePlate(values.licencePlate) && !errors.licencePlate) {
                    setHasLicencePlateWarning(true);
                } else {
                    setHasLicencePlateWarning(false);
                }
            }
        }, [values.licencePlate, hasLicencePlateWarning, checkedLatestValues]);

        useEffect(() => {
            if (checkedLatestValues) {
                if (!hasAutoEmail && emails[emails.length - 1]?.email) {
                    setValues((prevValues) => ({
                        ...prevValues,
                        email: emails[emails.length - 1]?.email || ''
                    }));
                    setHasAutoEmail(true);
                }
            }
        }, [emails, setValues, values.email, touched.email, hasAutoEmail, checkedLatestValues]);

        useEffect(() => {
            if (checkedLatestValues) {
                if (errors.licencePlate || errors.email) {
                    setDisablePaymentButtons(true);
                } else if (touched.email || touched.licencePlate) {
                    setDisablePaymentButtons(false);
                }
            }
        }, [errors, touched, checkedLatestValues]);

        useEffect(() => {
            if (checkedLatestValues) {
                if (values.licencePlate) {
                    const cleanedPlate = values.licencePlate
                        .replace(/[\s\-–]/g, '')
                        .toUpperCase()
                        .replace(/[^A-Z0-9]/g, '');

                    if (cleanedPlate !== values.licencePlate) {
                        setValues({...values, licencePlate: cleanedPlate});
                    }
                }
            }
        }, [values.licencePlate, checkedLatestValues]);

        // Set email value to useState
        useEffect(() => {
            if (checkedLatestValues) {
                if (values.email !== emailValue) {
                    setEmailValue(values.email);
                }
            }
        }, [values.email, checkedLatestValues]);

        // Set licence plate to useState
        useEffect(() => {
            if (checkedLatestValues) {
                if (values.licencePlate !== licencePlateValue) {
                    setLicencePlateValue(values.licencePlate);
                }
            }
        }, [values.licencePlate, checkedLatestValues]);

        return null;
    }

    const hasVehicles = vehicles.length > 0;
    const hasEmails = emails.length > 0;
    const hasCards = cards.length > 0;

    const validationSchema = Yup.object().shape({
        licencePlate: Yup.string().required().min(1).max(10),
        email: Yup.string().matches(/^[^@\s]+@[^@\s]+\.[a-zA-Z]{2,}$/),
        requestedMinutes: Yup.number().required(),
    });

    const [showNoEmailWarning, setShowNoEmailWarning] = useState(false);

    return (
        <div className={styles.containerWrapper}>
            {showNoEmailWarning ? (
                <div className={styles.warningOverlay}>
                    <div className={styles.warningOverlayContainer}>
                        <p className={styles.warningOverlayText}>{t("buy.noEmailWarning")}</p>
                        <div className={styles.warningButtons}>
                            <button
                                type="button"
                                onClick={(e) => {
                                    setSelectCard(false);
                                    setShowNoEmailWarning(false);
                                }}
                                className={`${styles.buyButton} ${styles.emailButton} ${styles.buttonFull}`}
                            >
                                {t("buy.enterEmail")}
                            </button>

                            {usePaymentMethod === "Card" || usePaymentMethod === "CardToken" ?
                            <button
                                type="button"
                                onClick={() => {
                                    const values = {
                                        licencePlate: licencePlateValue,
                                        email: emailValue,
                                        requestedMinutes: requestedMinutes,
                                    };
                                    setShowNoEmailWarning(false);
                                    if (hasCards) {
                                        setSelectCard(true);
                                        handleOrderTicket('CardToken', values, true);
                                    } else {
                                        handleOrderTicket('Card', values, true);
                                    }
                                }}
                                className={`${styles.buyButton} ${styles.buttonFull}`}
                            >
                                <Icon.PaymentCard
                                    className={styles.actionIcon}
                                    size={18}
                                />
                                &nbsp;&nbsp;{t('buy.payByCard')}
                            </button> : null }
                            {usePaymentMethod === "ApplePay" ?
                                <button
                                    disabled={disablePaymentButtons || disablePaymentButtonsDueToLimitExceeded}
                                    type="button"
                                    onClick={(e) => {
                                        const values = {
                                            licencePlate: licencePlateValue,
                                            email: emailValue,
                                            requestedMinutes: requestedMinutes
                                        };
                                        setShowNoEmailWarning(false);
                                        handleOrderTicket('ApplePay', values, true)
                                    }}
                                    className={`${styles.buyButton} ${styles.appleButton} ${styles.buttonFull}`}
                                >
                                    <Icon.ApplePay className={styles.appleIcon} size={16}/>&nbsp;Pay with Apple
                                </button> : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {showLoader && <FullscreenLoader/>}


            {selectCard ? (
                <div className={styles.container}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setSelectCard(false);
                        setDisablePaymentButtons(false)
                    }} className={styles.back}>
                        <Icon.CaretLeft size={12} color={"#C32B3E"}></Icon.CaretLeft>
                        &nbsp; {t("buy.backToBuy")}
                    </a>
                    <h1 className={styles.header}>{t("buy.cardPayment")}</h1>
                    <Formik
                        initialValues={{cardToken: ''}}
                        onSubmit={(data) => handleOrderTicket('CardToken', data)}

                    >
                        {({setValues}) => (
                            <Form>
                            <div className={styles.radioGroup}>
                                    {cards.map(card => (
                                        <label className={styles.radioOption} key={card.token}>
                                            <Field type="radio" name="cardToken" value={card.token} required />
                                            <div className={styles.radioLabel}>
                                                <span>{card.name}</span>
                                                <span>•••• {card.cardMaskedCLN.slice(-4)}</span>
                                            </div>
                                        </label>
                                    ))}
                                    <label className={styles.radioOption}>
                                        <Field type="radio" name="cardToken" value="new" required />
                                        <div className={styles.radioLabel}>
                                            <span>{t("buy.newCard")}</span>
                                        </div>
                                    </label>
                                </div>
                                <div className={styles.buyTicket}>
                                    <button
                                        disabled={disablePaymentButtons || disablePaymentButtonsDueToLimitExceeded}
                                        type="submit"
                                        onClick={() => setPaymentMethod(cards ? 'CardToken' : 'Card')}
                                        className={`${styles.buyButton} ${styles.buttonFull}`}
                                    >
                                        <Icon.PaymentCard className={styles.actionIcon} size={16} />&nbsp;&nbsp;{t("buy.payByCard")}&nbsp;-&nbsp;
                                        {priceForParking ?? parkingZone?.tariffInfo?.minPrice} {t("global.currency.czk")}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <div className={styles.container}>
                    {hasApiError ? (
                        <div className={styles.error}>
                            <p>{t('buy.apiError')}</p>
                        </div>
                    ) : null}

                    <Link to={`/${featureType}/` + sectionCode} className={styles.back}>
                        <Icon.CaretLeft size={12} color={"#C32B3E"}></Icon.CaretLeft>
                        &nbsp; {t("buy.back")}
                    </Link>
                    <h1 className={styles.header}>{t("buy.title")}</h1>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            licencePlate: '',
                            email: '',
                            requestedMinutes: options[0]
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleOrderTicket}
                    >
                        {({ setFieldValue, values, errors, touched, setValues, setFieldTouched}) => {
                            const hasErrorVehicles = errors.licencePlate && touched.licencePlate;
                            const hasErrorEmail = errors.email && touched.email;

                            return (
                                <Form>
                                <FormikSetValues/>
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            {featureType === "pz"
                                                ? <img alt="Parking" src={parkingIcon}/>
                                                : (featureType === "ib"
                                                    ? <img alt="Parking machine" height={24} src={infoBox}/>
                                                    : <img alt="Parking machine" height={24} src={parkingMachine}/>
                                                )}
                                        </div>
                                        <div className={styles.title}>
                                            <div className={styles.itemTitle}>
                                                {featureType === "pz" ? parkingZone?.sectionCode : parkingZone?.parkMachineCode}
                                            </div>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {featureType === "pz"
                                                ? t("home.MapSearchComponent.zones." + parkingZone?.sectionCategoryName)
                                                : (
                                                    featureType === "ib"
                                                        ? t("home.MapSearchComponent.zones.infoBox")
                                                        : t("home.MapSearchComponent.zones.parkingMachine")
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <img alt="Time" src={timeIcon} />
                                        </div>
                                        <div className={styles.timeInfo}>
                                            <div className={`${styles.title} ${styles.titleBuy}`}>
                                                <div className={`${styles.itemTitle} ${styles.itemTitleBuy}`}>
                                                    {t('buy.time')}
                                                </div>
                                            </div>
                                            <div className={styles.textMuted}>
                                                {t('buy.toDate')} {toDate}
                                            </div>
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.itemEndExtended} ${styles.inputMobileMax} ${styles.selectTime}`}>
                                            <SelectField
                                                name="requestedMinutes"
                                                value={values.requestedMinutes}
                                                onChange={value => {
                                                    setFieldValue('requestedMinutes', value);
                                                    setRequestedMinutes(value);
                                                }}
                                                options={options}
                                                styles={colourStyles}
                                                isSearchable={false}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    Option: SelectOption
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <div className={`${styles.info} ${hasErrorVehicles ? styles.inputError : ''} ${hasLicencePlateWarning ? styles.inputWarning : ''}`}>
                                        <div className={styles.icon}>
                                            <Icon.Car color={hasErrorVehicles ? "#CC1818" : "" || hasLicencePlateWarning ? "#ffa340" : "#1D4F8B"}></Icon.Car>
                                        </div>
                                        <div className={styles.title}>
                                            <div className={styles.itemTitle}>
                                                {t('buy.car')}
                                            </div>
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.itemEndExtended} ${styles.inputMobileMax}`}>
                                            {!hasVehicles ? (
                                                <Field
                                                    name="licencePlate"
                                                    placeholder={t('buy.carPlaceholder')}
                                                    className={styles.input}
                                                    maxLength={10}
                                                    onInput={() => setFieldTouched('licencePlate', true, true)}
                                                />
                                            ) : (
                                                <VehiclePicker name="licencePlate" hasError={hasErrorVehicles} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <div className={`${styles.info} ${hasErrorEmail ? styles.inputError : ''}`}>
                                        <div className={styles.icon}>
                                            <Icon.Email color={hasErrorEmail ? "#CC1818" : "#1D4F8B"}></Icon.Email>
                                        </div>
                                        <div className={`${styles.title} ${styles.titleMobile}`}>
                                            <div className={styles.itemTitle}>
                                                {t('buy.mail')}
                                            </div>
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.itemEndExtended}`}>

                                            {!hasEmails ? (
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    onInput={(e) => {
                                                        setFieldTouched('email', true, true);
                                                    }}
                                                    placeholder={t('buy.mailPlaceholder')}
                                                    className={styles.input}
                                                    value={values.email || ''}
                                                />
                                            ) : (
                                                <EmailPicker name="email" hasError={hasErrorEmail} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <img alt="Parking" src={pricingIcon} />
                                        </div>
                                        <div className={styles.title}>
                                            <div className={styles.itemTitle}>
                                                {t('buy.price')}
                                            </div>
                                        </div>
                                        <div className={styles.text500}>
                                            {priceForParking ?? parkingZone?.tariffInfo?.minPrice} {t('global.currency.czk')}
                                        </div>
                                    </div>
                                </div>

                                {(hasErrorVehicles || hasErrorEmail) &&
                                    <div className={styles.inputErrorColor}>
                                        <p>{t('buy.formError')}</p>
                                    </div>
                                }

                                {disablePaymentButtonsDueToLimitExceeded &&
                                    <div className={styles.inputErrorColor}>
                                        <p>{t('buy.parkingSessionLimitExceeded')}</p>
                                    </div>
                                }

                                {(hasLicencePlateWarning) &&
                                    <div className={styles.inputErrorColor}>
                                        <p>{t('global.licencePlateWarning')}</p>
                                    </div>
                                }

                                <div className={styles.buyTicket}>
                                    {isSafari ? (
                                        <button
                                            disabled={disablePaymentButtons || disablePaymentButtonsDueToLimitExceeded}
                                            type="button"
                                            onClick={(e) => {
                                                setValues({
                                                    licencePlate: values.licencePlate,
                                                    email: values.email,
                                                    requestedMinutes: values.requestedMinutes
                                                });
                                                handleOrderTicket('ApplePay', values)
                                            }}
                                            className={`${styles.buyButton} ${styles.appleButton}`}
                                        >
                                            <Icon.ApplePay className={styles.appleIcon} size={16} />&nbsp;Pay with Apple
                                        </button>
                                    ) : (
                                        <button
                                            disabled={disablePaymentButtons || disablePaymentButtonsDueToLimitExceeded}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                alert(t('buy.wip')); /* handleOrderTicket('GooglePay', values) */
                                            }}
                                            className={`${styles.buyButton} ${styles.googleButton}`}
                                        >
                                            <Icon.GooglePay className={styles.googleIcon} size={16} />&nbsp;&nbsp;Google Pay
                                        </button>
                                    )}
                                    <button
                                        disabled={disablePaymentButtons || disablePaymentButtonsDueToLimitExceeded}
                                        type="button"
                                        onClick={() => {
                                            setValues({
                                                licencePlate: values.licencePlate,
                                                email: values.email,
                                                requestedMinutes: values.requestedMinutes
                                            });
                                            (hasCards ? setSelectCard(true) : handleOrderTicket('Card', values));
                                        }}
                                        className={styles.buyButton}
                                    >
                                        <Icon.PaymentCard className={styles.actionIcon} size={18} />&nbsp;&nbsp;{t('buy.payByCard')}
                                    </button>
                                </div>
                            </Form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        </div>
    );
}

export default Buy;
