import React from 'react';
import {useField, Form, FormikProps, Formik, FieldHookConfig} from 'formik';
import ReactSelect from 'react-select';
import SelectOption from "../../SelectOption/SelectOption";

type Props = {
	label?: any,
	placeholder: any,
	options: any,
	name: string,
    onChange?: (option) => void,
	isSearchable?: boolean
};

export const colourStyles = {
	container: (styles, { isFocused }) => {
		return{
			...styles,
			width: "100%",
			minWidth: "100%",
			backgroundColor: isFocused ? "#fff" : '#F3F3F5',
			border: "1px solid #3A3C42",
			borderRadius: "20px",
			boxShadow: 'none',
			'&:focus': { outline: 0, border: 0 }
		};
	},
	menu: (styles) => {
		return{
			...styles,
			width: "100%",
			boxShadow: '0px 8px 8px 0px rgba(58, 24, 26, 0.16)',
			outline: 0,
			marginTop: "5px",
			borderRadius: "20px"
		};
	},
	control: (styles, { isFocused }) => {
		return{
			...styles,
			backgroundColor: isFocused ? "#fff" : '#F3F3F5',
			border: 0,
			outline: 'none !important',
			borderRadius: "20px",
			boxShadow: 'none',
			minHeight: '40px',
			"&:hover": {
				border: 0,
				outline: 0,
				boxShadow: 'none'
			},
			"&:focus": {
				border: 0,
				outline: 0,
				boxShadow: 'none'
			},
		};
	},
	placeholder:  styles => ({ ...styles, color: '#757575', textWrap: "nowrap", fontStyle: "italic", fontSize: "small", opacity: 0.7}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#F3F2F5' : 'transparent',
			boxSizing: 'border-box',
			color: '#000000',
			borderRadius: isDisabled ? '0' : '20px',
			cursor: isDisabled ? 'not-allowed' : isFocused ? 'pointer' : 'default',
			'&:hover': { backgroundColor: isDisabled ? '#F3F3F5' :'transparent' }
		};
	}
};

const Select = ({ label, ...props }: Props) => {
	const [field, meta, helpers] = useField({
		...props,
		type: 'hidden'
	});

    const onChange = option => {
        helpers.setValue(option ? option.value : null);

        if (props.onChange) {
            props.onChange(option);
        }
    };


	return (
		<>
			<ReactSelect
				placeholder={props.placeholder}
                value={props.options.find(option => option.value === field.value) || null}
				options={props.options}
				onChange={onChange}
				onBlur={(e) => helpers.setTouched(true)}
				name={props.name}
				components={{ Option: SelectOption }}
				styles={colourStyles}
				isSearchable={props.isSearchable ?? true}
			/>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	);
};

export default Select;