import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useParkingSession() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = useIndexedDB("parkingSession");
	const [parkingSession, setParkingSession] = useIntervalState((setState) => {
		getAll().then((parkingSessionFromDB) => {
			setState(parkingSessionFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return parkingSession.find(parkingSession => parkingSession[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((parkingSession) => {
                if (parkingSession && parkingSession.id) {
                    return deleteRecord(parkingSession.id)
                }

                return Promise.resolve();
            });
		}
	}

	return {parkingSession, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll, getAll};
}